<template>

	<div>
		<div class="block-header">
			<div class="row">
				<div class="col-lg-7 col-md-6 col-sm-12">
					<h2>
						<small class="text-muted"></small>
					</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<h2>欢迎使用</h2>
							<h2>大冶招商局信息填报系统</h2>
	
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		methods:{
			
		}
		
	}
	
</script>

<style scoped>
	
	.body{
		text-align: center;
		margin: 10% auto;
	}
	
	h3{
		color:green;
	}
	
</style>
